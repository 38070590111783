html {
    scroll-behavior: smooth;
}

body {
    font-family: "AvenirLTStd-Roman" !important;
    font-size: 15px;
    font-weight: bold;
    color: var(--text-color);
    overflow-x: hidden;
}

.p-landing-page {
    font-family: "AvenirLTStd-Book" !important;
    font-weight: 400;
    line-height: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 19px;
    color: var(--headline-text-color);
    font-family: "AvenirLTStd-Black" !important;
}

label {
    font-size: 19px;
    color: var(--headline-text-color);
}

a {
    text-decoration: none !important;
}

th {
    font-size: 15px !important;
}

td {
    font-size: 13px !important;
}

.form-check-input:checked {
    background-color: #fd5744 !important;
    border-color: #fd5744 !important;
}

:root {
    --text-color: #6d6d6d;
    --headline-text-color: #4d4d4d;
    --action-button-color: #fd5744;
    --mouse-over-color: #fd796b;
    --text-size: 15px;
    --nav-text-size: 14px;
    --headline-size: 19px;
    --main-background-color: #fbfbfb;
    --nav-hover-menu: #fd5744;
    --bs-border-radius: 0.25rem !important;
    --bs-border-radius-lg: 0.3rem !important;
}

.btn {
    padding: 0 !important;
}

.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.btn-primary {
    background-color: var(--action-button-color) !important;
    border: none !important;
    padding: 8px 70px !important;
    font-size: 13px !important;
    font-family: "AvenirLTStd-Black" !important;
    color: #ffff !important;
    outline: none !important;
    text-transform: uppercase;
    padding-top: 4.8px !important;
    padding-bottom: 4px !important;
}

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn-primary:hover {
    background-color: var(--mouse-over-color) !important;
}

.primary-dropdown {
    background-color: var(--action-button-color) !important;
    border: none !important;
    padding: 8px 70px !important;
    font-size: 13px !important;
    font-family: "AvenirLTStd-Black" !important;
    color: #ffff !important;
    outline: none !important;
    text-transform: uppercase;
    padding-top: 4.8px !important;
    padding-bottom: 4px !important;
}

.primary-dropdown:hover,
.show > .btn-success.dropdown-toggle {
    background-color: var(--action-button-color) !important;
    border: none !important;
    color: #ffff !important;
    border: none;
}

.nav-tabs .nav-link {
    background-color: #6d6d6d !important;
    color: #fff !important;
    margin-right: 5px;
}

.nav-tabs .nav-link.active {
    background-color: #ffffff !important;
    color: #4d4d4d !important;
}

.no-bg {
    background-color: none !important;
}

.container-bg {
    background-color: #fbfbfb !important;
}

.text-primary {
    color: #fd5744 !important;
    background-color: #fd5744 !important;
}

.bg-light {
    background-color: #fd5744 !important;
}

.btn-outline-primary {
    background-color: var(--action-button-color) !important;
    border: none !important;
    text-transform: uppercase;
    font-size: 13px !important;
    font-family: "AvenirLTStd-Black" !important;
    color: #ffff !important;
    outline: none !important;
    right: 10px;
    padding-top: 4.8px !important;
    padding-bottom: 4px !important;
}

.btn-outline-primary:hover {
    background-color: var(--mouse-over-color) !important;
    color: #fbfbfb !important;
}

.btn-secondary-filter {
    background-color: #5c636a !important;
    border: none !important;
    text-transform: uppercase;
    font-size: 13px !important;
    font-family: "AvenirLTStd-Black" !important;
    color: #ffff !important;
    outline: none !important;
    right: 10px;
    padding-top: 4.8px !important;
    padding-bottom: 4px !important;
}

.modal-fullscreen {
    width: 80vw !important;
    margin-inline: auto !important;
    justify-content: center;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-style: none !important;
}

.drag-n-drop {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #4d4d4d;
    border-style: dashed;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
}

.nav-tabs .nav-link.active {
    border-color: rgba(109, 109, 109, 0.5) rgba(109, 109, 109, 0.5) #ffffff !important;
}

.scroll-dashboard {
    height: 65vh;
    overflow-y: auto;
    background-color: #ffffff;
}

.accordion-item {
    border: none !important;
    padding: 40px !important;
    border-radius: 14px !important;
    box-shadow: 0px 6px 16px rgba(74, 58, 255, 0.19) !important;
}

.accordion {
    width: 65%;
    margin: 0 auto !important;
}

.accordion-button {
    padding: 0 !important;
    border-bottom: none !important;
    border-radius: 0 !important;
}

.accordion-button:not(.collapsed):focus {
    outline: none !important;
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
}

.accordion-body {
    padding: 0px !important;
    padding-top: 10px !important;
}

.accordion-button:not(.collapsed) {
    color: #4d4d4d !important;
}

.accordion-button {
    color: #4d4d4d !important;
}

.accordion-button::after {
    position: relative !important;
    top: 0px;
    right: -25px;
}

.accordion-item-border {
    border: 2px solid #ff5744 !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data: image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff' ><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' /></svg>") !important;
    background-color: #ff5744 !important;
}

.accordion-button::after {
    background-image: url('data: image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23FF5744" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" /></svg>') !important;
    box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.1) !important;
    background-position: center center;
    width: 30px !important;
    height: 30px !important;
    border-radius: 15px !important;
}

.accordion-button:not(.collapsed) {
    background-color: #ffffff !important;
    outline: none !important;
}

.accordion-button:not(.collapsed)::after {
    transform: none !important;
}

.button:focus:not(:focus-visible) {
    outline: none !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.dropdown-menu {
    max-height: 200px !important;
    overflow-y: auto !important;
}

.btn-outline-secondary {
    color: #fd5744 !important;
    background-color: #ffff !important;
    border-color: #fd5744 !important;
}

.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #fd5744 !important;
    border-color: #fd5744 !important;
}

.btn-close {
    color: #000;
}

.mb-1 {
    margin-bottom: 10px;
}

.mb-2 {
    margin-bottom: 20px;
}

.mb-3 {
    margin-bottom: 30px;
}

.mt-1 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 20px;
}

.mt-3 {
    margin-top: 30px;
}

.w-1 {
    width: 10%;
}

.w-2 {
    width: 20%;
}

.w-3 {
    width: 30% !important;
}

.w-4 {
    width: 40% !important;
}

.w-5 {
    width: 50%;
}

.w-6 {
    width: 60%;
}

.w-7 {
    width: 70%;
}

.w-8 {
    width: 80%;
}

.w-9 {
    width: 90% !important;
}

.w-10 {
    width: 100%;
}

.text-no-wrap {
    white-space: nowrap;
}

.tWeight-bold {
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.carton-modal-text {
    width: 50% !important;
    text-align: center;
}

.custom-acordion {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 65%;
    margin: 0 auto !important;
}

.custom-acordion-header {
    width: 90%;
    border-bottom: 1.5px solid #4d4d4d;
    position: relative;
    padding: 10px;
    cursor: pointer;
}

.plus-btn {
    position: absolute;
    right: -20px;
    top: 12px;
}

.custom-acordion-body {
    display: none;
    width: 100%;
}

.active-acordion {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.added-file {
    background-color: rgba(255, 255, 255, 0) !important;
    color: #4d4d4d !important;
}

.added-file:hover {
    background-color: rgba(255, 255, 255, 0) !important;
}

.down {
    cursor: pointer;
}

.up {
    cursor: pointer;
}

.head-line-box {
    position: absolute;
    top: -35px;
}

.table-scroll {
    max-height: 50vh !important;
    overflow-y: auto !important;
}

.sticky-header {
    position: sticky;
    background-color: #ffff;
    top: 0;
    z-index: 3;
}

.modal {
    overflow-y: hidden !important;
}

.settings-form {
    width: 70%;
    display: flex;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
}

.settings-user-list {
    width: 100%;
    display: flex;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
}

.settings-user-list-table {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.form-check-label {
    font-size: 15px !important;
}

.form-select {
    font-size: 0.75rem !important;
}

.amazon-product {
    width: 90%;
}

.add-user {
    /* width: 21% !important; */
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center !important;
    white-space: nowrap !important;
    width: 170px !important;
}

.preview-public-api-key-tooltip > .tooltip-inner {
    background-color: #787878;
    color: #fff;
    height: 26px;
    min-width: unset !important;
    max-width: unset !important;
}

.bs-tooltip-start > .tooltip-arrow::before {
    border-left-color: #787878 !important;
}

.preview-public-api-key-tooltip-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 0 6px 0 2px;
    font-size: 13px;
}

.mr-2-custom {
    margin-right: 10px !important;
}

.ml-2-custom {
    margin-left: 10px !important;
}

.alert-possition {
    position: absolute;
    right: 20px;
    bottom: 100px;
}

.rc-slider-track {
    background-color: #fd5744 !important;
}

.rc-slider-handle-custom {
    border: solid 2px #fd5744 !important;
    opacity: 1 !important;
    z-index: 0 !important;
}

.foo {
    min-width: 100px !important;
}

.modal {
    display: block !important;
    /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog {
    overflow-y: initial !important;
}

.modal-body {
    max-height: 90vh;
    overflow-y: auto;
}

.link-primary {
    color: var(--action-button-color) !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}

.input {
    border-color: #e20338 !important;
}

.radio-select .form-check-input {
    width: 1.3rem !important;
    height: 1.3rem !important;
    margin-top: 0 !important;
}

.rdrInputRanges {
    display: none !important;
}

.custom-check-width input {
    width: 1.5em !important;
    height: 1.5em !important;
}

.powered-by-stripe {
    height: 28px;
}

.powered-by-stripe-register {
    height: 20px;
}

label {
    font-weight: normal;
}

.right-nav-on-hover:hover {
    color: #fd5744 !important;
}

.h-34px {
    height: 34px !important;
}

.overlay-trigger-custom div div {
    background-color: #000000ae !important;
}

.fz-15 {
    font-size: 15px !important;
}

.max-w-430px {
    max-width: 430px !important;
}

.max-h-60vh {
    max-height: 60vh;
}

.max-h-500 {
    max-height: 500px;
}

.overflow-y {
    overflow-y: auto;
}

.color-gray-light {
    color: #606060;
}

.loading-container {
    position: absolute;
    background-color: #fbfbfb9e;
    z-index: 999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tracking-loading-container {
    position: absolute;
    background-color: #fbfbfb;
    z-index: 999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.h-calc-360 {
    height: calc(100vh - 360px);
}

.h-calc-425 {
    height: calc(100vh - 425px);
}

.rc-slider-disabled {
    background-color: unset !important;
}

.loading-container-full-height {
    height: calc(100vh - 120px) !important;
}

.full-tab-loading {
    height: calc(100vh - 160px) !important;
}

.full-tab-loading-dashboard {
    height: calc(100vh - 270px) !important;
}

.btn-ff-group-button {
    background-color: var(--action-button-color) !important;
    border: none !important;
    padding: 4px 0 !important;
    font-size: 13px !important;
    font-family: "AvenirLTStd-Black" !important;
    color: #ffff !important;
    outline: none !important;
    text-transform: uppercase;
    min-width: 240px;
}

.center-loading-from-all-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75%;
}

.progress-spinner-circular {
    animation: progress-spinner-rotate 1s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
}

.progress-spinner-circle {
    stroke-dasharray: 100, 100;
    stroke-dashoffset: 0;
    stroke-linecap: round;
}

.progress-spinner-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1;
}

@keyframes progress-spinner-rotate {
    100% {
        transform: rotate(360deg);
    }
}

.shortly-titles-table th {
    text-wrap: nowrap !important;
}

.tracking-table-tooltip.sku {
    max-width: 400px;
    word-break: break-all;
    height: unset;
}

.modal {
    padding-left: 0px !important;
}

.modal-fullscreen .modal-content {
    height: 80% !important;
}

.height-unset {
    height: unset !important;
}
