body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: "AvenirLTStd-Roman";
    src: url("./assets/fonts/AvenirLTStd-Roman.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "AvenirLTStd-Book";
    src: url("./assets/fonts/AvenirLTStd-Book.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "AvenirLTStd-Black";
    src: url("./assets/fonts/AvenirLTStd-Black.ttf") format("truetype");
    font-display: swap;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
